<template>
  <div>
    <static-fullscreen-card v-show="!idItemShow">
      <template v-slot:header></template>
      <template v-slot:actions>
        <a-btn-refresh color="primary" @click="updateData()" />
        <portal to="v-main"> </portal>
      </template>
      <a-table-f-api
        ref="table"
        :api="url"
        :model="model"
        :useQuery="false"
        :defaults="defaults"
        :selectedRows="selectedRows"
        @click="
          counter += 1;
          onDetectClick($event);
        "
        @change-filter="onChangeFilter($event)"
      />
      <popup-menu v-model="popumMenuShow" :items="popupMenuList" :position="popupMenuPosition" />
    </static-fullscreen-card>
    <ViewItem v-if="idItemShow" :idShow="idItemShow" @close="itemShowClose($event)" />
    <task-dialog v-model="showTaskDialog" :id="idEdit"></task-dialog>
  </div>
</template>

<script>
import taskDialog from "./dialogs/taskDialog.vue";
import { getAccess, doubleClickDetect, popupMenu } from "@/components/mixings";

export default {
  mixins: [getAccess, doubleClickDetect, popupMenu],
  components: {
    taskDialog,
    ViewItem: () => import("./views/tasksView"),
  },
  data() {
    return {
      view: 1,
      idEdit: 0,
      idItemShow: 0,
      dialog: false,
      showTaskDialog: false,
      m: this.$store.getters["config/get"].models.tasks,
      statuses: this.$store.getters["config/get"].models.tasks.statuses,
      url: "/mechti/tasks-goods",
      filterName: "task",
      defaults: {
        sort: [
          { key: "unviewedscomments_total", order: "DESC", default: true },
          { key: "id", order: "DESC", default: true },
        ],
        filters1: { type: { condition: "=", value: "construction-goods" } },

        paramName: "task",
      },
    };
  },
  created() {
    const filters = this.$root.getSessionFilter(this.filterName);
    this.$root.title = "Задачи (чек-лист) OLD" + (filters ? "(отфильтрованы)" : "");
    this.defaults.sessionFilters = filters || null;
  },
  computed: {
    statusList() {
      let list = [];
      //return this.statuses;
      let idx;
      let type = {
        new: "*Новые",
        progress: "*В процессе",
        ready: "*Завершенные",
        cancel: "*Отмененные",
      };
      let text;
      for (const s of this.statuses.filter((el) => el.status)) {
        text = type[s.status] || s.status;
        idx = list.findIndex((el) => el.text == text);
        if (idx > -1) {
          list[idx].value.push(s.value);
        } else {
          list.push({ value: [s.value], text });
        }
      }

      for (const s of this.statuses) {
        idx = list.findIndex((el) => el.text == s.text);
        if (idx > -1) {
          list[idx].value.push(s.value);
        } else {
          list.push({ value: [s.value], text: s.text });
        }
      }
      return list;
    },
    model() {
      /**заменяем отображение даты конца на др элемент. с подсветкой */
      let model = JSON.parse(JSON.stringify(this.m.list));
      const status = this.statuses.filter((el) => {
        return ["ready", "cancel"].includes(el?.status);
      });
      model.forEach((el) => {
        if (el.name == "date_end") {
          el.type = "dateColor";
          el.status = status.map((s) => {
            return s.value;
          });
        }
        if (el.name == "user_name") {
          // el.textColor = "user_viewedon";
          el.textColor = (values) => {
            return values.user_viewedon ? "" : "red";
          };
        }
        if (el.name == "status") {
          el.search = this.statusList;
        }
        if (el?.sourceList) {
          let list = this.m[el.sourceList] || [];
          if (el.name == "type")
            list = list.filter((f) => {
              return f.value == "construction-goods";
            });
          el.options = list;
        }
      });
      return model;
    },
    modelCron() {
      let model = JSON.parse(JSON.stringify(this.mCron.list));
      model.forEach((el) => {
        if (el?.sourceList) {
          el.options = this.mCron[el.sourceList];
        }
      });
      return model;
    },
  },
  watch: {
    showTaskDialog() {
      if (!this.showTaskDialog) {
        this.updateData();
      }
    },
  },
  methods: {
    itemShowClose() {
      this.loading = true;
      this.$refs.table.updateRowData(this.idItemShow);

      this.loading = !true;
      this.idItemShow = 0;
    },

    updateData() {
      this.$refs.table.updateData();
    },

    onSingleClick(d) {
      if (d.field?.isMenu) {
        this.showPopupMenu(d.field.name, d.row, d.event);
      }
    },
    onDoubleClick(d) {
      if (this.showCronList) {
        this.showCronDialogFun(d.row.id);
        this.showCronDialog = true;
      } else {
        this.idItemShow = d.row.id;
        return;
        this.$router.push({ name: "tasks_view", params: { id: d.row.id } });
      }
    },

    createNew() {
      this.showTaskDialogFun(0);
    },

    showTaskDialogFun(id) {
      this.idEdit = id;
      this.showTaskDialog = true;
    },
    createNewCron() {
      this.showCronDialogFun(0);
    },
    showCronDialogFun(id) {
      this.idEdit = id;
      this.showCronDialog = true;
    },
    async updateTask(row) {
      await this.$axios.post(this.url, row);
      this.$root.$emit("show-info", {
        text: "Данные записаны",
      });
    },
    onChangeFilter(f) {
      this.$root.setSessionFilter(this.filterName, f);
    },
  },
};
</script>
